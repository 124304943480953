import ImageCounterImg from "./../assets/images/projects/counter.jpg";
import LotteryImg from "./../assets/images/projects/lottery.jpg";
import LauncherImg from "./../assets/images/projects/launcher.jpg";
import SiteTouchImg from "./../assets/images/projects/site_touch.jpg";
import SocialImg from "./../assets/images/projects/social.jpg";
import ToDoImg from "./../assets/images/projects/todo.jpg";
import PortfolioImg from "./../assets/images/projects/portfolio.jpg";
import GameLauncherImg from "./../assets/images/projects/game_launcher.jpg";
import DontPageImg from "./../assets/images/projects/dont_page.jpg";
import AppImg from "./../assets/images/projects/app.jpg";

export const ProjectData = [
  {
    title: "Don't Smoke - App Store & Play Market",
    image: AppImg,
    description:
      "React Native; NestJS; MongoDB; Persist; Subscriptions; Ads and more...",
    link: "https://dontsmoke.vasyl.site/",
  },
  {
    title: "Don't Smoke Landing",
    image: DontPageImg,
    description: "Design, React, Animation, Invite System",
    link: "https://dontsmoke.vasyl.site/",
  },
  {
    title: "Game launcher",
    image: GameLauncherImg,
    description:
      "Freelance, React Native, TypeScript, Java, Redux, and more...",
    link: "#",
  },
  {
    title: "Portfolio",
    image: PortfolioImg,
    description:
      "React, hook, useState, LocalStorage, multi language clean css, and more.",
    link: "https://github.com/vasyok28/PORTFOLIO",
  },
  {
    title: "To Do List",
    image: ToDoImg,
    description:
      "React, Redux, API requests, clean css, axios, thunks, and more.",
    link: "https://github.com/vasyok28/To-Do-List",
  },
  {
    title: "Social Network",
    image: SocialImg,
    description:
      "React, Redux, API requests, clean css, axios, thunks, and more.",
    link: "https://github.com/vasyok28/samurai",
  },
  {
    title: "Counter",
    image: ImageCounterImg,
    description: "React, useState, CSS, HTML, LocalStorage",
    link: "https://github.com/vasyok28/Counter",
  },
  {
    title: "Lottery US",
    image: LotteryImg,
    description:
      "UI/UX, Design; PHP, Laravel, SQL, Builder, CSS3, HTML5; BEM; Deploy.",
    link: "#",
  },
  {
    title: "TOUCH RP: Game Launcher",
    image: LauncherImg,
    description: "JS, Node JS, Electron JS, CSS, HTML, Jquery; UI/UX, Design.",
    link: "https://touch-rp.com/",
  },
  {
    title: "Touch RP: Site",
    image: SiteTouchImg,
    description:
      "Laravel, SQL, Builder, CSS3, HTML5; BEM; Donate integration, and more.",
    link: "https://touch-rp.com/",
  },
];
