import React from 'react';
import './AnimationBackground.scss';

export const AnimationBackground = () => {
    return (
        <div className="area">
            <ul className="circles">
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
                <li> </li>
            </ul>
        </div>
    );
}
