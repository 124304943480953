export const MySkillLang = {
	ru: {
		title: 'Мои навыки',
	},
	en: {
		title: 'My skills',
	},
	ukr: {
		title: 'Мої навички',
	},
	ro: {
		title: 'Abilitatile mele',
	},
};
